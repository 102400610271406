<template>
    <div :class="data.Class">
        <ul :class="data.UlClass" v-if="!data.get">
            <li v-ripple :class="[item.Class, activeCondition(item.ActiveCondition, n) ? item.ActiveClass : '']" @click="mainAction(item.OnClick, n)" v-for="(item, n) in data.Data" :key="n">
                <div v-html="item.Text" v-if="item.Text"></div>
                <div :class="cms.Class" v-for="(cms, indexCms) in item.Content" :key="indexCms">
                    <div :class="cms.Left.Class" v-if="cms.Left">
                        <icons-component :data="cms.Left.Icons" v-if="cms.Left.Icons" />
                        <span :class="text.Class" v-for="(text, indexText) in cms.Left.Text" :key="indexText">
                            {{ stringReader(text.Value, item) }}
                        </span>
                    </div>
                    <div :class="cms.Right.Class" v-if="cms.Right">
                        <!-- <span :class="text.Class" v-for="(text, indexText) in cms.Right.Text" :key="indexText">
                            {{ stringReader(text.Value, item) }}
                        </span> -->
                        <div :class="text.Class" v-for="(text, indexText) in cms.Right.Text" :key="indexText">
                            <div v-html="text.Value"></div>
                        </div>
                    </div>
                </div>
                <!-- <div :class="item.Left.Class" :style="item.Left.Style" v-if="item.Left">
                    <b-icon v-if="item.Left.Icon" :icon="item.Left.Icon" :custom-size="item.Left.CustomSizeIcon || 'mdi-24px'"></b-icon>
                </div>
                <div :class="item.Right.Class" v-if="item.Right">
                    <span :class="item.Right.TitleClass" v-if="item.Right.Title">{{ item.Right.Title }}</span>
                    <span :class="item.Right.SubTitleClass" v-if="item.Right.SubTitle">{{ item.Right.SubTitle }}</span>
                </div> -->
            </li>
        </ul>
        <form @submit="onsubmit" :id="data.Id" v-else>
            <ul :class="data.UlClass">
                <li v-ripple @click="mainAction(data.OnClick, item)" :class="data.LiClass" v-for="(item, n) in stringReader(data.get)" :key="n">
                    <div :class="cms.Class" v-for="(cms, indexCms) in data.Content" :key="indexCms">
                        <div :class="cms.Left.Class" v-if="cms.Left">
                            <form-component :data="cms.Left.Form" v-if="cms.Left.Form" :prev="item" />
                            <div v-html="stringReader(text.Value, item)" :class="text.Class" v-for="(text, indexText) in cms.Left.Text" :key="indexText"></div>
                        </div>
                        <div :class="cms.Right.Class" v-if="cms.Right">
                            <div v-html="stringReader(text.Value, item)" :class="text.Class" v-for="(text, indexText) in cms.Left.Text" :key="indexText"></div>
                        </div>
                    </div>
                </li>
            </ul>
        </form>
    </div>
</template>
<script>
export default {
    components: {
        FormComponent: () => import('./form'),
        IconsComponent: () => import('./icons')
    },
    props: {
        data: {
            type: Object
        }
    },
    mounted () {
        console.log('list component', this.data.get, this.stringReader(this.data.get))
    }
}
</script>
<style lang="scss" scoped>
    ul {
        list-style-type: none;
        margin: 0;
    }
</style>
